import { MutationHookOptions, gql, useMutation } from '@apollo/client';

export const DELETE_COMPANY_LOGO = gql`
  mutation DeleteCompanyLogo {
    deleteCompanyLogo {
      id
      logoUrl
    }
  }
`;

export interface WhitelabelConfig {
  id: string;
  logoUrl: string;
}

export interface DeleteCompanyLogoResult {
  deleteCompanyLogo: WhitelabelConfig;
}

export function useWhitelabelConfigLogoDeleteMutation(withRefetchQueries = true) {
  const mutationOpts: MutationHookOptions = {
    context: {
      headers: {
        'apollo-require-preflight': true,
      },
    },
  };
  if (withRefetchQueries) mutationOpts.refetchQueries = ['GetUser'];
  return useMutation<DeleteCompanyLogoResult>(DELETE_COMPANY_LOGO, mutationOpts);
}
