import { AdvanceCallback } from '@components/block/Quest/Quest';
import { AnyStep } from '@components/block/Quest/utils';
import { Task } from '@dieterApi/task/useTaskQuery';
import {
  UserApplication,
  UserQuestionnaireApplication,
  UserQuestionnaireApplicationTopic,
  UserTopicStatusApplicationsQuestionnaires,
} from '@dieterApi/user/useUserQuery';
import { useUserUpdateNavStateMutation } from '@dieterApi/user/useUserUpdateNavStateMutation';
import { useMediaQuery } from '@mui/material';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { Updater, useImmer } from 'use-immer';
import { MessageNames } from './MessageContext';
import { useUser } from './UserContext';
import { NavigationSection, NavigationSubsection, OnboardingApps, Topics, initialNavigationState } from './constants';
import { parseNavState } from './utils';

interface INavigationContext {
  navigation: NavigationState;
  setNavigation: Updater<NavigationState>;
  purgeNavigation: () => void;
  hasLoaded: boolean;
}

export const NavigationContext = createContext<INavigationContext>({} as INavigationContext);

interface Props {
  children: React.ReactNode;
}

const NAV_STATE_EXPIRY = 1000 * 60 * 60 * 24 * 7; // 1 week

// we separate between states which should not be considered after NAV_STATE_EXPIRY and persistent state
const VOLATILE_STATE: Array<keyof Partial<NavigationState>> = ['animateBadge', 'phoneCTAdismissed']; //'activeSection', 'activeSubSection',
const PERSISTENT_STATE: Array<keyof Partial<NavigationState>> = [
  'limitedOfferDismissed',
  'mfaNotificationDismissed',
  'onboarding',
  'preferredTab',
  'languageDisclaimerDismissed',
  'ignoredTasks',
  'expertModeEnabled',
  'justFinishedQuestionnaireRemoteId',
  'taskTutorials',
  'newTasks',
  'dismissedTasks',
  'dismissedMessages',
  'newDocuments',
  'showTopicCompleteConfirmation',
  'revisitTutorialSeen',
  'progress',
  'chatsViewed',
];

export function NavigationProvider({ children }: Props) {
  const { user } = useUser();

  const [navigation, setNavigation] = useImmer<NavigationState>(initialNavigationState);
  const [hasLoaded, setHasLoaded] = useState(false);

  const [updateNavState] = useUserUpdateNavStateMutation();
  const isMobile = useMediaQuery('(max-width:767px)');

  // always save previous navigation state in ref
  const prevNavigation = usePrevious(navigation);

  // PURGE NAVIGATION STATE
  const purgeNavigation = () => {
    setNavigation(initialNavigationState);
  };

  useEffect(() => {
    if (!user) setHasLoaded(false);
  }, [user]);

  // LOAD NAVIGATION STATE FROM USER
  useEffect(() => {
    if (user) {
      if (user.navState) {
        // only restore navState if it was persisted not longer than an hour ago
        const newState = parseNavState(user.navState);

        let newVolatileState: Partial<NavigationState> = {};
        if (newState.lastUpdate && Date.now() - newState.lastUpdate.getTime() < NAV_STATE_EXPIRY) {
          // only set this if NAV_STATE_EXPIRY has not passed
          newVolatileState = filterObjectByKeys(newState, VOLATILE_STATE) as Partial<NavigationState>;
        }
        const newPersistentState = filterObjectByKeys(newState, PERSISTENT_STATE) as Partial<NavigationState>;

        setNavigation({
          ...navigation,
          ...newVolatileState,
          ...newPersistentState,
        });
      }
      setHasLoaded(true);
    }
  }, [user?.id]);

  useEffect(() => {
    user &&
      setNavigation(
        (nav) =>
          void (nav.dashboardLocked = user.hasPassword === false || user.conditionsConsent === false || !user.confirmed)
      );
  }, [user?.hasPassword, user?.conditionsConsent]);

  // SAVE NAVIGATION STATE TO DATABASE (only if it has been loaded before)
  useEffect(() => {
    // navigateToSection(navigation.activeSection);
    // compare navigation and prevNavigation and only save if they differ in any of the VOLATILE_STATE or PERSISTENT_STATE
    const persistentStateHasChanged = Object.entries(navigation).map(([key, value]) => {
      if (
        VOLATILE_STATE.includes(key as keyof NavigationState) ||
        PERSISTENT_STATE.includes(key as keyof NavigationState)
      ) {
        const prevValue = prevNavigation && prevNavigation[key as keyof NavigationState];
        return JSON.stringify(value) !== JSON.stringify(prevValue);
      }
    });

    if (user && hasLoaded && persistentStateHasChanged.some(Boolean)) {
      const oldNavState = user.navState;
      const stateItems: any = {};
      for (const key of VOLATILE_STATE.concat(PERSISTENT_STATE)) {
        stateItems[key] = navigation[key];
      }

      const stateToPersist = {
        // add all navigation state items which should be persisted
        ...stateItems,
        lastUpdate: new Date(),
      };

      const newNavState = JSON.stringify(stateToPersist);

      if (oldNavState !== newNavState) {
        updateNavState({
          variables: {
            navState: newNavState,
          },
        });
      }
    }
  }, [navigation]);

  // set isMobile
  useEffect(() => {
    setNavigation((nav) => void (nav.isMobile = isMobile));
  }, [isMobile]);

  // set onboardingApplication
  useEffect(() => {
    setNavigation(
      (nav) =>
        void (nav.onboardingApp = user?.topics
          .flatMap((t) => t.applications)
          .find((app) => app.id === nav.onboarding.appId))
    );
  }, [navigation.onboarding.appId]);

  const checkHubspotChatStatus = () => {
    // check if the chat is loaded and/or open
    const isLoaded = window.HubSpotConversations?.widget?.status()?.loaded || false;
    setNavigation((nav) => void (nav.hubspot.chatIsLoaded = isLoaded));
  };

  // initialise Roadblock behaviour
  useEffect(() => {
    setNavigation(
      (nav) =>
        void (nav.roadBlock.open = (topic: UserTopicStatusApplicationsQuestionnaires) =>
          setNavigation((nav) => {
            nav.roadBlock.modalOpen = true;
            nav.roadBlock.topic = topic;
          }))
    );
    setNavigation(
      (nav) =>
        void (nav.roadBlock.close = () =>
          setNavigation((nav) => {
            nav.documentPaper = {
              modalOpen: false,
              questionnaire: undefined,
              index: 0,
            };
            nav.roadBlock.modalOpen = false;
            nav.roadBlock.topic = undefined;
          }))
    );

    // constantly check if the chat is loaded and/or open
    const interval = setInterval(checkHubspotChatStatus, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <NavigationContext.Provider value={{ navigation, setNavigation, purgeNavigation, hasLoaded }}>
      {children}
    </NavigationContext.Provider>
  );
}

export type NavigationScope = 'dashboard' | 'quest' | 'quest_external' | 'onboarding';
export type OfferType = 'Basic' | 'Premium' | 'Ticket';

export type OnboardingAppMapping<T> = { [key in OnboardingApps]: T };

interface MessageHash {
  name: MessageNames;
  contextHash: string;
}

export interface NavigationState {
  scope?: NavigationScope;
  activeSection?: NavigationSection;
  activeTopic?: Topics;
  activeSubSection?: NavigationSubsection;
  onboarding: {
    appId?: OnboardingApps;
    inProgress: boolean;
  };
  onboardingApp?: UserApplication;
  preferredTab?: 'Documents' | 'Processes' | 'Forms' | null; // this should determine if we move to documents or tasks when opening a topic
  dashboardLocked: boolean;
  passwordModalOpen: boolean;
  dismissedMessages: MessageHash[];
  animateBadge: boolean;
  questionnaireWrapup: boolean; // this indicates that the finishing tasks are still in progress
  revisitTutorialSeen: boolean;
  isPrintView: boolean;
  itemListOpen: boolean;
  expertModeEnabled: boolean;
  documentPaper: {
    modalOpen: boolean;
    index: number;
    questionnaire?: UserQuestionnaireApplicationTopic;
  };
  // This is a modal which is shown when the questionnaire was marked as UNTOUCHED or INCOMPLETE after a version update
  // and the user tried to open a document. It informs the user about the update and closes the document.
  questionnaireHasChanged: {
    modalOpen: boolean;
    questionnaire?: UserQuestionnaireApplication;
  };
  phoneCTAdismissed: boolean;
  limitedOfferDismissed: boolean;
  mfaNotificationDismissed: boolean;
  justFinishedQuestionnaireRemoteId?: string | null;
  roadBlock: {
    modalOpen: boolean;
    topic?: UserTopicStatusApplicationsQuestionnaires;
    open: (topic: UserTopicStatusApplicationsQuestionnaires) => void;
    close: () => void;
  };
  languageDisclaimerDismissed: { [key in Topics]?: boolean };

  isMobile: boolean;
  lastUpdate?: Date;
  showTopicCompleteConfirmation: any;
  taskTutorials: any;
  // tutorialActive: string;
  taskOpen: {
    tutorial: string;
    confirm: string;
  };
  animate: boolean;
  newTasks: any;
  dismissedTasks: Record<string, boolean>;
  ignoredTasks: Record<string, boolean>;
  newDocuments: any;
  progress: number;
  boom: boolean; // triggers a bigBlast fireworks animation
  showSavedAnimation: boolean; // for showing a small notification in the navigation, whenever an answer was saved
  hubspot: {
    showChat: boolean;
    chatIsLoaded: boolean;
    chatIsOpen: boolean;
  };
  chatsViewed: string[];
  consent: {
    hubspot: boolean;
    sentry: boolean;
  };
}

// from scope: quest
export interface NavigationState {
  stepIndex: number;
  steps: Array<AnyStep>;
  onAdvance: AdvanceCallback;
  questTask: Task;
}

const filterObjectByKeys = (raw: any, keys: Array<string>) => {
  return Object.keys(raw)
    .filter((key) => keys.includes(key))
    .reduce((obj: any, key: any) => {
      obj[key] = raw[key];
      return obj;
    }, {});
};

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
