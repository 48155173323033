import { useNavigation } from '@app/hooks/useNavigation';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { NavigationMobile } from '@components/block/Navigation/NavigationMobile';
import { Loading, Swap } from '@components/ui';
import { IQuestionnaire } from '@legalosApi/types/IQuestionnaire';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import cx from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdvanceCallback, UpdateQuestionCallback } from '../Quest';
import { Step } from '../Step/Step';
import { AnyStep, QuestionStep } from '../utils';
import './quest-stream.sass';
export interface Props {
  onAdvance: AdvanceCallback;
  onUpdateQuestion: UpdateQuestionCallback;
  questionnaire: IQuestionnaire;
  stepIndex: number;
  steps: Array<AnyStep>;
  revisit: boolean;
}

export function Stream(props: Props) {
  const { stepIndex, steps, revisit } = props;
  const { t } = useTranslation();
  const { navigation, setNavigation } = useNavigation();
  const { enqueueSnackbar } = useSnackbar();
  const step: AnyStep | undefined = steps[stepIndex] as QuestionStep;

  const shouldSkipOnExpertMode =
    navigation.expertModeEnabled &&
    step?.question.tags.process.includes('INFO') &&
    !step?.question.tags.process.includes('UPLOAD') &&
    stepIndex !== steps.length - 1;
  const handleDeactivateExpertMode = () => {
    setNavigation((nav) => void (nav.expertModeEnabled = false));
    enqueueSnackbar(t('components.block.quest.expert_mode.deactivate'), { variant: 'success' });
  };

  useEffect(() => {
    if (shouldSkipOnExpertMode && step) {
      props
        .onUpdateQuestion(step?.question._id, step?.question.options[0].value, true)
        .then(() => props.onAdvance(step?.id));
    }
  }, [shouldSkipOnExpertMode, step]);

  if (shouldSkipOnExpertMode) {
    return (
      <div
        // className="flex flex-col items-center gap-3 mt-4"
        className={cx(
          'md:grow-[6] max-md:flex-1 md:basis-0 relative md:mt-0 mb-32 pr-4 md:pr-0 flex flex-col items-center gap-4',
          {
            'mt-[40px]': revisit,
          }
        )}
      >
        <Loading />
        <NewReleasesIcon fontSize="large" />
        <span className="text-center">
          {t('components.block.quest.expert_mode.activated')}
          <br /> {t('components.block.quest.expert_mode.details')}
        </span>

        <button className="text-primary-root hover:text-primary-700 underline" onClick={handleDeactivateExpertMode}>
          {t('components.block.quest.expert_mode.deactivate_button')}
        </button>
      </div>
    );
  }

  return (
    <Swap
      className={cx('md:grow-[6] max-md:flex-1 md:basis-0 relative md:mt-0 mb-32 pr-4 md:pr-0', {
        'mt-[40px]': revisit,
      })}
      pageClassName="dtQuestStream__page"
      uri={step ? step.id : '@empty'}
    >
      <>
        {step ? <Step {...props} step={step} /> : null}
        <NavigationMobile />
      </>
    </Swap>
  );
}
