import { NavigationContext } from '@app/context/NavigationContext';
import {
  NavigationSection,
  NavigationSubsection,
  Topics,
  pathNavSectionMapping,
  pathSubSectionMapping,
  pathTopicMapping,
} from '@app/context/constants';
import { sectionUrl, subSectionUrl, topicUrl } from '@app/context/utils';
import { useContext, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

export function useNavigation() {
  const history = useHistory();
  const { navigation, setNavigation, hasLoaded, purgeNavigation } = useContext(NavigationContext);

  // extract subpath from pathname after /dashboard
  const { sectionPath, topicPath, subSectionPath } =
    useRouteMatch<{
      sectionPath?: string;
      topicPath?: string;
      subSectionPath: string;
    }>('/dashboard/:sectionPath/:topicPath?/:subSectionPath?')?.params || {};

  useEffect(() => {
    // if there is navigation from outside, we need to sync the activeSection, activeTopic, activeSubSection with the pathname
    sectionPath && setNavigation((nav) => void (nav.activeSection = pathNavSectionMapping['/' + sectionPath]));
    topicPath && setNavigation((nav) => void (nav.activeTopic = pathTopicMapping['/' + topicPath]));
    subSectionPath && setNavigation((nav) => void (nav.activeSubSection = pathSubSectionMapping['/' + subSectionPath]));
  }, [sectionPath, topicPath, subSectionPath]);

  const navigateDashboard = (section: NavigationSection, topic?: Topics, subSection?: NavigationSubsection) => {
    setNavigation((nav) => void (nav.activeSection = section));
    if (section !== NavigationSection.Topic) {
      setNavigation((nav) => void (nav.activeTopic = undefined));
      setNavigation((nav) => void (nav.activeSubSection = undefined));
      history.push(`/dashboard${sectionUrl(section)}`);
    } else {
      setNavigation((nav) => void (nav.activeTopic = topic));
      if (!subSection) {
        setNavigation((nav) => void (nav.activeSubSection = undefined));
        topic && history.push(`/dashboard${sectionUrl(section)}${topicUrl(topic)}`);
      } else {
        setNavigation((nav) => void (nav.activeSubSection = subSection));
        topic && history.push(`/dashboard${sectionUrl(section)}${topicUrl(topic)}${subSectionUrl(subSection)}`);
      }
    }
  };
  return { navigation, setNavigation, purgeNavigation, navigateDashboard, hasLoaded };
}
