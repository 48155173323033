import { CtaButton } from '@app/components/ui/CtaButton/CtaButton';
import { useTasks } from '@app/context/TaskContext';
import { useUser } from '@app/context/UserContext';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { pickLatest } from '@app/utils/pickLatest';
import { Timer } from '@components/block/Navigation/Timer';
import { NotificationModal, Spinner } from '@components/ui';
import { UserFlowItem } from '@components/ui/UserFlowItem/UserFlowItem';
import { TaskType } from '@dieterApi/task/useTaskQuery';
import { ApplicationClass, UserApplicationQuestionnaires } from '@dieterApi/user/useUserQuery';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import SchoolIcon from '@mui/icons-material/School';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IconButton, ModalProps, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TaskWithApp } from '../../Tasks/TaskList';
import { Dashboard } from '../../useDashboard';

import { useNavigation } from '@app/hooks/useNavigation';
import { getTopicStates } from '@app/utils/getTopicStates';
import { NewBadge } from '@components/ui/NewBadge/NewBadge';
import { useTranslation } from 'react-i18next';
import { UserFlowItemType } from '../../constants';
import './formitem.sass';

interface Props {
  dashboard: Dashboard;
  isFocusTask?: boolean;
  app: UserApplicationQuestionnaires;
  task?: TaskWithApp;
  index: number;
}

export function FormItem({ dashboard, app, index, task, isFocusTask }: Props) {
  const { t } = useTranslation();
  const { user } = useUser();
  const { navigation, setNavigation } = useNavigation();
  const { enqueueSnackbar } = useSnackbar();
  const tasks = useTasks();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const TOPIC_LOCKED_TEXT = t('dashboard.topic.forms.formitem.locked');

  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);

  // when the corresponding topic is disabled (when a user is not a subscriber), we want to open the CallToAction
  const topic = user?.topics.find((topic) => topic.applications.map((app) => app.id).includes(app.id));
  // choose latest questionnaire
  const questionnaire = pickLatest(app.questionnaires);

  const split = app.splitApplication;
  const formProgress = (split ? questionnaire?.sectionProgress[index] : questionnaire?.progress) || 0;
  const revisit = task?.type === TaskType.REVISIT;
  const formFinished = formProgress === 1;
  const taskDone = task?.done || false;
  const formAndTaskFinished = formFinished && taskDone;
  const inProgress = formProgress > 0;
  // MH: I removed the mandatory existence of a task, for a form to be unlocked
  // This was incompatible with the direct onboarding route to DSE, AVV, etc.
  const formLocked = split && index > 0 && questionnaire?.sectionProgress[index - 1] !== 1; // || !task;
  const thisDuration = task?.duration || 5;
  const thisModifiedAt = (split ? questionnaire?.sectionModifiedAt[index] : questionnaire?.updatedAt) || '';

  const { isLocked } = topic ? getTopicStates(topic, user) : { isLocked: true };
  const isPublic = app.tags?.map((t) => t.tag).includes('public');
  const isLegacy = app.tags?.map((t) => t.tag).includes('legacy');
  const isBroken =
    (questionnaire?.isBroken && [ApplicationClass.Contract, ApplicationClass.Document].includes(app.class)) || false;
  const isDocument = app.class === ApplicationClass.Document;

  useEffect(() => {
    if (formFinished && !taskDone && !revisit) {
      // set task to done, when it has not happened yet
      task && task.setStatus(true);
    }
  }, [formAndTaskFinished, taskDone, revisit]);

  const handleButton = (section?: number, revisit: boolean = false) => {
    if (user?.isReadOnly && (!formFinished || revisit)) {
      enqueueSnackbar(t('common.readonly'), { variant: 'error' });
      return;
    }
    if (questionnaire) {
      dashboard.open(questionnaire.id, section, revisit);
    } else {
      dashboard.create(app.id, app.splitApplication ? 1 : undefined);
    }
  };

  const handleDuplicate = () => {
    if (user?.isReadOnly) {
      enqueueSnackbar(t('common.readonly'), { variant: 'error' });
      return;
    }
    setDuplicateModalOpen(true);
  };

  const handleShow = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (app.documentLabels.length > 1) {
      setAnchorEl(event.currentTarget);
    } else {
      openDocument(0);
    }
  };

  const openDocument = (index: number) => {
    if (isLocked && !isPublic) {
      topic && navigation.roadBlock.open(topic);
    } else {
      setNavigation((nav) => {
        nav.justFinishedQuestionnaireRemoteId = null;
        nav.preferredTab = undefined;
      });
    }
    const q = user?.questionnaires?.find((q) => q.id === questionnaire?.id);
    setNavigation((nav) => {
      nav.documentPaper = {
        modalOpen: true,
        questionnaire: q,
        index,
      };
    });
  };

  const CTAMapStart: Record<ApplicationClass, string> = {
    [ApplicationClass.Analysis]: t('common.cta.start_analysis'),
    [ApplicationClass.Contract]: t('common.cta.create_contract'),
    [ApplicationClass.Document]: t('common.cta.create_document'),
    [ApplicationClass.Training]: t('common.cta.start_training'),
  };

  const CTAMapFinish: Record<ApplicationClass, string> = {
    [ApplicationClass.Analysis]: t('common.cta.finish_analysis'),
    [ApplicationClass.Contract]: t('common.cta.finish_contract'),
    [ApplicationClass.Document]: t('common.cta.finish_document'),
    [ApplicationClass.Training]: t('common.cta.finish_training'),
  };

  const CTAMapShow: Record<ApplicationClass, string> = {
    [ApplicationClass.Analysis]: t('common.cta.show_analysis'),
    [ApplicationClass.Contract]: t('common.cta.show_contract'),
    [ApplicationClass.Document]: t('common.cta.show_document'),
    [ApplicationClass.Training]: t('common.cta.show_training'),
  };

  const CTAMapRework: Record<ApplicationClass, string> = {
    [ApplicationClass.Analysis]: t('common.cta.rework_analysis'),
    [ApplicationClass.Contract]: t('common.cta.rework_contract'),
    [ApplicationClass.Document]: t('common.cta.rework_document'),
    [ApplicationClass.Training]: t('common.cta.rework_training'),
  };

  return (
    <div className="h-full">
      <UserFlowItem
        type={UserFlowItemType.questionnaire}
        title={t('common.section') + ': ' + topic?.title}
        progress={revisit ? 0.9 : formProgress}
        isFocusTask={isFocusTask}
        done={formFinished}
        broken={isBroken && formFinished}
        onIgnore={task?.setIgnore}
        isIgnored={task?.isIgnored}
        // isNew={task?.isNew && !inProgress}
        // isNewVersion={revisit}
        topRightElements={
          <div className="flex gap-3 items-center w-full place-content-end">
            {!revisit && thisModifiedAt && (
              <div className="text-xs grow">
                {thisModifiedAt &&
                  t('dashboard.topic.forms.formitem.lastchanged') +
                    DateTime.fromJSDate(new Date(thisModifiedAt))
                      .setLocale(user?.locale || 'en')
                      .toRelative()}
              </div>
            )}

            {!tasks ? (
              <Spinner size="small" />
            ) : formFinished ? (
              isBroken ? (
                <RevisitBadge />
              ) : isLegacy ? (
                <Tooltip title={t('dashboard.topic.forms.formitem.legacy')}>
                  <div className="flex items-center gap-1">
                    <span className="text-warning font-medium brightness-75">{t('common.legacy')}</span>
                    <DoDisturbIcon className="text-warning" fontSize="small" />
                  </div>
                </Tooltip>
              ) : (
                <span className="text-success font-medium brightness-75">{t('common.done')}</span>
              )
            ) : (
              <>
                {task?.isNew && <NewBadge />}
                <Timer duration={revisit ? 5 : thisDuration} />
              </>
            )}
          </div>
        }
        bodyElements={
          <div className="w-full flex flex-col gap-2 ">
            <div className="grow flex flex-col gap-2">
              {/* <span className="text-gray-400 text-[0.8em] leading-3">{t('common.section') + ': ' + topic?.title}</span> */}
              <div className="text-lg">
                <div>{app.sectionLabels[index]}</div>
              </div>
            </div>

            <div className="position-relative flex items-center gap-2">
              {formFinished && user?.hasFeature('MultiTenancy') && (
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                  title={t('dashboard.topic.forms.formitem.applyothertenants')}
                >
                  <IconButton onClick={() => handleDuplicate()}>
                    <AddBusinessIcon />
                  </IconButton>
                </Tooltip>
              )}
              {formFinished &&
                !isBroken &&
                (isDocument ? (
                  <CtaButton
                    onClick={() => handleButton(index + 1, formFinished)}
                    disabled={formLocked}
                    spinner={dashboard.affects('open', questionnaire?.id)}
                    btnColor="simple"
                  >
                    {CTAMapFinish[app.class]}
                  </CtaButton>
                ) : (
                  <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={CTAMapFinish[app.class]}>
                    {dashboard.affects('open', questionnaire?.id) ? (
                      <Spinner size="small" />
                    ) : (
                      <IconButton onClick={() => handleButton(index + 1, formFinished)}>
                        {app.class === ApplicationClass.Training ? <SchoolIcon /> : <EditIcon />}
                      </IconButton>
                    )}
                  </Tooltip>
                ))}

              {formLocked && (
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                  title={t('dashboard.topic.forms.formitem.firstfinishothers')}
                >
                  <div className="tooltipAnchor" />
                </Tooltip>
              )}
              {isLocked && !isPublic && (
                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} title={TOPIC_LOCKED_TEXT}>
                  <LockIcon className="text-secondary-600" fontSize="small" />
                </Tooltip>
              )}

              {
                <CtaButton
                  onClick={formFinished && !isBroken ? handleShow : () => handleButton(index + 1, formFinished)}
                  disabled={formLocked}
                  spinner={dashboard.isBusy && !formLocked}
                  data-testid={'button-open-form-' + app.id + '-' + (index + 1)}
                  btnColor={formFinished ? 'simple' : 'primary'}
                  // icon={topicLocked ? <LockIcon className="text-secondary-600" fontSize="small" /> : undefined}
                >
                  {false
                    ? t('common.unlock')
                    : formFinished
                      ? isBroken
                        ? CTAMapRework[app.class]
                        : CTAMapShow[app.class]
                      : revisit
                        ? t('common.revisit')
                        : inProgress
                          ? t('common.proceed')
                          : CTAMapStart[app.class]}
                </CtaButton>
              }
            </div>
            <DuplicateModal open={duplicateModalOpen} onClose={() => setDuplicateModalOpen(false)} app={app} />
          </div>
        }
      />
      <DocumentMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} app={app} onShow={openDocument} />
    </div>
  );
}

interface DuplicateModalProps extends Omit<ModalProps, 'children'> {
  app: UserApplicationQuestionnaires;
}

const DuplicateModal = ({ open, onClose, app, ...props }: DuplicateModalProps) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [duplicateQuestionnaire, { loading }] = useDuplicateQuestionnaireMutation();
  const [queryQuestionnaires, { data: questionnairesData, loading: questionnairesLoading }] = useQuestionnairesQuery();

  // choose latest questionnaire
  const questionnaire = pickLatest(app.questionnaires);

  const [loadingForCompany, setLoadingForCompany] = useState<string | null>(null);

  const otherTenants = user?.companies.filter((company) => company.id !== user?.company?.id);

  const handleDuplicate = (companyId: string) => {
    if (questionnaire) {
      setLoadingForCompany(companyId);
      duplicateQuestionnaire({
        variables: {
          localQuestionnaireId: questionnaire?.id,
          companyId,
        },
      });
    }
  };

  useEffect(() => {
    if (!loading && !questionnairesLoading) {
      setLoadingForCompany(null);
    }
  }, [questionnairesLoading]);

  useEffect(() => {
    if (otherTenants?.length && questionnaire?.id) {
      const otherIds = otherTenants
        ?.map((company) => {
          // find out if the other company already has an instance of this app
          const existingQuestionnaires = company.questionnaires?.filter((q) => q.application.id === app.id);
          const existingLatestQuestionnaire =
            existingQuestionnaires.length > 0
              ? existingQuestionnaires?.reduce((prev, current) =>
                  prev?.createdAt > current.createdAt ? prev : current
                )
              : null;
          return existingLatestQuestionnaire?.id;
        })
        .filter(Boolean) as string[];

      queryQuestionnaires({
        variables: {
          ids: [...otherIds, questionnaire.id],
        },
      });
    }
    // TODO: THIS LOOPS
  }, [otherTenants?.flatMap((c) => c.questionnaires.flatMap((q) => q.id)).join('')]);

  return (
    <NotificationModal open={open} onClose={onClose} {...props}>
      <div className="dtDuplicateModal flex flex-col gap-20">
        <div className="text-lg font-medium">{t('dashboard.topic.duplicate_modal.title')}</div>
        {otherTenants?.map((company) => {
          // find out if the other company already has an instance of this app
          const existingQuestionnaires = company.questionnaires?.filter((q) => q.application.id === app.id);
          const existingLatestQuestionnaire =
            existingQuestionnaires.length > 0
              ? existingQuestionnaires?.reduce((prev, current) =>
                  prev?.createdAt > current.createdAt ? prev : current
                )
              : null;

          const existingProgress = questionnairesData?.getQuestionnaires.find(
            (q) => q.id === existingLatestQuestionnaire?.id
          )?.progressHash;
          const currentProgress = questionnairesData?.getQuestionnaires.find(
            (q) => q.id === questionnaire?.id
          )?.progressHash;
          const sameSame = existingProgress === currentProgress;

          return (
            <div key={company.id} className="flex justify-between items-center">
              <div>{company.name}</div>
              {loadingForCompany === company.id ? (
                <Spinner size="small" />
              ) : existingLatestQuestionnaire ? (
                sameSame ? (
                  <CheckCircleOutlineIcon color="success" />
                ) : (
                  <div className="flex gap-10">
                    <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                      title="Es existiert bereits eine Fragebogeninstanz mit abweichenden Antworten"
                    >
                      <WarningAmberIcon color="warning" />
                    </Tooltip>
                    <Link to="#" onClick={() => handleDuplicate(company.id)}>
                      {t('common.overwrite')}
                    </Link>
                  </div>
                )
              ) : (
                <Link to="#" onClick={() => handleDuplicate(company.id)}>
                  {t('common.accept')}
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </NotificationModal>
  );
};

import { useDuplicateQuestionnaireMutation } from '@dieterApi/questionnaire/useDuplicateQuestionnaireMutation';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';

// code-snippet from https://mui.com/material-ui/react-menu/

interface DocumentMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  app: UserApplicationQuestionnaires;
  onShow: (index: number) => void;
}

export default function DocumentMenu({ anchorEl, handleClose, app, onShow }: DocumentMenuProps) {
  const { t } = useTranslation();

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Menu
        className="dtAccountMenu"
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            // overflow: 'visible',
            maxHeight: '600px',
            overflowY: 'auto',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {app.documentLabels.map((label, idx) => {
          return (
            <MenuItem key={label}>
              <button
                onClick={() => {
                  onShow(idx);
                }}
              >
                {label}
              </button>
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
}

import { useQuestionnairesQuery } from '@dieterApi/questionnaire/useQuestionnairesQuery';
import { animated, useSpring } from 'react-spring';

const RevisitBadge = () => {
  const { t } = useTranslation();
  const ringingAnimation = useSpring({
    loop: true,
    from: { transform: 'rotate(5deg)' },
    to: [{ transform: 'rotate(0deg)' }],
    // duration: 3000,
    config: { tension: 2000, friction: 3 },
    reset: true,
  });

  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      title="Der Fragebogen wurde aktualisiert und du musst ggf. einige deiner Antworten überprüfen"
    >
      <animated.div style={ringingAnimation} className="bg-danger px-2 py-1 text-xs text-white rounded-lg">
        {t('components.ui.newbadge.newversion')}
      </animated.div>
    </Tooltip>
  );
};
